import React from 'react';
import { connect } from 'react-redux';
import { roleChange } from '../../../../state/Login';
import SelectModal from '../../components/SelectModal';
import IconRoleCompany48px from '../../assets/Icon_Role-Company_48px.svg';
import IconRoleEraisik48px from '../../assets/Icon_Role-Eraisik_48px.svg';
import {updateUserData} from "../../../../Networking";

export const RoleChange = React.memo(props => (
    <SelectModal {...{
        title: props.translation.title,
        onCloseButtonClick: props.onCloseButtonClick,
        items: [
            ...[props.login.privateClientData && {
                ...props.login.privateClientData,
                secondary: props.translation.privateClient,
                img: IconRoleEraisik48px
            }],
            ...(props.login.businessClientData ? props.login.businessClientData.map(x => ({
                ...x,
                secondary: props.translation.businessClient,
                img: IconRoleCompany48px
            })) : [])].filter(x => x).map(x => ({
                ...x,
                text: x.fullName || x.name,
                onClick: () => {
                    props.onCloseButtonClick();
                    props.roleChange(x);
                    props.updateUserData(
                        {
                            action: 'updateUserRole',
                            roleId: (x.secondary === props.translation.businessClient) ? x.clientId : null,
                            token: props.login.token,
                            userId: props.login.privateClientData.userId
                        },
                        data => {},
                        error => {},
                    );
                }
            }))
    }} />
));

export default connect(
    state => ({
        login: state.login,
        translation: state.translation.roleChange
    }),
    dispatch => {
        return {
            roleChange: (newRole) => dispatch(roleChange(newRole)),
            updateUserData: (body, success, error) => updateUserData(dispatch, body, success, error)
        }
    })(RoleChange);
