import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import OutlinedTextButton from '../../components/OutlinedTextButton';
import ContentContainer from '../../../components/ContentContainer';
import Loader from '../../../components/Loader';
import GoogleMapReact from 'google-map-react';
import { Modal, ModalInputText } from "./Modal";
import './Address.scss';
import IconAddress38px from '../../../assets/Icon_Address-38px.svg';
import MapMarker from '../../../assets/Map_Marker.svg';
import { mapStyle } from '../../../../assets/MapStyle';

const EditAddress = connect(state => ({ translation: state.translation.manage.address.editAddressModal }))(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            address: props.address.fullAddress, isLoaderVisible: false,
            isDone: false, isError: false, result: null
        };
    }

    onAddressSave = (address) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        if (this.state.isLoaderVisible) {
            return;
        }
        this.setState({ isLoaderVisible: true }, () => {
            this.props.manageRequest(
                {
                    "action": "editClient",
                    "accNr": accnr,
                    "token": this.props.login.token,
                    "clientAddress": address
                },
                data => {
                    const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                    if (!_accnr || _accnr !== accnr) {
                        return; //role changed
                    }
                    this.setState({
                        result: data.records.result,
                        isDone: data.records.statusCode === 200,
                        isError: data.records.statusCode !== 200,
                        isLoaderVisible: false
                    });
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        });
    }

    onAddressChange = (e) => {
        this.setState({ address: e.target.value });
    }

    render() {
        return (
            <Modal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onModalClose,
                content:
                    <ModalInputText {...{
                        text: this.props.translation.addressField,
                        id: "address",
                        value: this.state.address,
                        onChange: this.onAddressChange
                    }} />,
                isSaveButton: true,
                onActionButtonClick: () => this.onAddressSave(this.state.address),
                actionButtonTitle: this.props.translation.saveButton,
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 112
            }} />
        )
    }
});

const Marker = React.memo(props =>
    <div className="address-marker"><img src={MapMarker} alt="" /></div>
);

class Address extends PureComponent {
    render() {
        const street = this.props.address && this.props.address.street;
        const city = this.props.address && this.props.address.city;
        const state = this.props.address && this.props.address.state;
        const country = this.props.address && (this.props.address.country || this.props.address.countryCode);
        const lat = (this.props.address && this.props.address.latitude) || null;
        const lng = (this.props.address && this.props.address.longitude) || null;
        return (
            <div className="manage-address" id="address">
                <ContentContainer {...{
                    className: "manage-address-address-container",
                    title:
                        <p className="manage-address_label">
                            <img className="manage-address_label-img" src={IconAddress38px} alt="" />
                            <span className="manage-address_label-text">{this.props.translation.title}</span>
                        </p>,
                    action: <OutlinedTextButton {...{
                        className: "manage-address_button-change",
                        text: this.props.translation.editButton,
                        onClick: () => this.props.onModalOpen(<EditAddress {...{
                            onModalClose: this.props.onModalClose,
                            address: this.props.address,
                            manageRequest: this.props.manageRequest,
                            login: this.props.login
                        }} />)
                    }} />,
                    content:
                        <div className="manage-address-content">
                            <TransitionGroup component={null}>
                                {this.props.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                    <Loader {...{ className: "manage-address-loader" }} />
                                </CSSTransition>}
                                <CSSTransition key={"content-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                    <div className="manage-address_text">
                                        <p className="manage-address_text-top">{this.props.companyName}<br />{street}<br />{city}</p>
                                        <p className="manage-address_text-bot">{state}<br />{country}</p>
                                    </div>
                                </CSSTransition>
                            </TransitionGroup>
                        </div>
                }} />
                <div className="manage-address_map">
                    <GoogleMapReact
                        center={[lat, lng]}
                        defaultZoom={14}
                        options={maps => ({ styles: mapStyle })}>
                        {lat && lng && <Marker {...{ lat: lat, lng: lng }} />}
                    </GoogleMapReact>
                </div>
            </div>
        );
    }
}

export default connect(state => ({ translation: state.translation.manage.address }), null)(Address);
