export const TYPE_BANK_LINKS_CHECKBOX = 'TYPE_BANK_LINKS_CHECKBOX';
export const TYPE_LANGUAGE_CHANGE = 'TYPE_LANGUAGE_CHANGE';
export const TYPE_SWITCH_ROLE = 'TYPE_SWITCH_ROLE';
export const TYPE_UPDATE_USER_DATA = 'TYPE_UPDATE_USER_DATA';
export const TYPE_LOGGED_IN = 'TYPE_LOGGED_IN';
export const TYPE_LOGGED_OUT = 'TYPE_LOGGED_OUT';

export const banklinksCheckbox = (checked) => ({
    type: TYPE_BANK_LINKS_CHECKBOX,
    banklinksCheckbox: checked
});

export const languageChange = (newLanguage) => ({
    type: TYPE_LANGUAGE_CHANGE,
    newLanguage
});

export const roleChange = (newRole) => ({
    type: TYPE_SWITCH_ROLE,
    newRole
});

export const userDataChange = (newUserData) => ({
    type: TYPE_UPDATE_USER_DATA,
    newUserData,
});

export const loggedIn = (data) => ({
    type: TYPE_LOGGED_IN,
    data
});

export const loggedOut = () => ({
    type: TYPE_LOGGED_OUT
});

export const initialLoginState = {
    privateClientData: null,
    businessClientData: null,
    token: null,
    currentRole: null,
};

export default function login(state = initialLoginState, action) {
    switch (action.type) {
        case TYPE_BANK_LINKS_CHECKBOX:
            return { ...state, banklinksCheckbox: action.banklinksCheckbox };

        case TYPE_LANGUAGE_CHANGE:
            if (state.privateClientData) {
                return {
                    ...state,
                    privateClientData: {
                        ...state.privateClientData,
                        language: action.newLanguage,
                    },
                };
            }
            return state;

        case TYPE_SWITCH_ROLE:
            return { ...state, currentRole: action.newRole };

        case TYPE_UPDATE_USER_DATA:
            return {
                ...state,
                privateClientData: applyUserData(state.privateClientData, action.newUserData),
                currentRole: applyUserData(state.currentRole, action.newUserData),
            };

        case TYPE_LOGGED_IN:
            const defaultBusinessClient = action.data.businessClientData
                ?.find(bClient => bClient.isDefault === '1');

            return {
                ...state,
                ...action.data,
                currentRole: defaultBusinessClient || action.data.privateClientData || action.data.businessClientData[0],
            };

        case TYPE_LOGGED_OUT:
            return { ...state, ...initialLoginState };

        default:
            return state;
    }
}

function applyUserData(prev, next) {
    if (prev.userId !== next.userId) {
        return prev;
    }

    return {
        ...prev,
        fullName: `${next.firstName} ${next.lastName}`,
        email: next.email,
        prone: next.phone,
    };
}
