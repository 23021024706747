import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import TopAd from './components/TopAd';
import Welcome from './components/Welcome';
import Dials from './components/Dials';
import OlerexPlus from './components/OlerexPlus';
import Cards from './components/Cards';
import CreditCards from './components/CreditCards';
import UserDetails from './components/UserDetails';
import FuelUsage from './components/FuelUsage';
import { roleOrLanguageChanged } from '../../../../Utils';
import { userDataChange } from '../../../../state/Login';
import { privateDashboardRequest, getTicketsRequest } from '../../../../Networking';
import Loader from '../../components/Loader';
import GameProgress from './components/GameProgress';

import styles from './Overview.module.scss';

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowAllModalVisible: false,
            isTopAdVisible: false,
            content: null,
            isLoaderVisible: true,
            tickets: null
        };
    }

    onRightDialClick = () => {
        this.setState({ isTopAdVisible: !this.state.isTopAdVisible });
    }

    onTopAdClose = () => {
        this.setState({ isTopAdVisible: false });
    }

    onShowAllOpen = (loyaltyProgress, loyaltyInfo) => {
        this.props.onOlerexPlusModalOpen({
            onShowAllClose: this.onShowAllClose,
            text: this.props.translation.olerexPlus.modal.text,
            loyaltyProgress: loyaltyProgress,
            loyaltyInfo: loyaltyInfo
        });
    }

    onShowAllClose = () => {
        this.props.onOlerexPlusModalClose();
    }

    downloadData = () => {
        const userId = this.props.login?.currentRole?.userId;
        if (!userId) {
            return; // Not in private role
        }

        this.setState({ isLoaderVisible: true }, async () => {
            this.props.dashboardRequest({
                userId,
                token: this.props.login.token,
            },
            data => {
                const _userId = this.props.login?.currentRole?.userId;
                if (!_userId || _userId !== userId) {
                    return; // Role changed
                }

                const saleStatisticsFormated = {};

                for (const x of data.records.saleStatistics || []) {
                    if (!saleStatisticsFormated[x.saleYear]) {
                        saleStatisticsFormated[x.saleYear] = {};
                    }

                    if (!saleStatisticsFormated[x.saleYear][x.saleMonth]) {
                        saleStatisticsFormated[x.saleYear][x.saleMonth] = Math.round(x.totalQty);
                    }
                }

                if (data.records.infoBubble2) {
                    data.records.infoBubble2.fill = 360;
                }

                this.props.userDataChange(data.records.userData);

                this.setState({
                    content: {
                        ...data.records,
                        saleStatistics: saleStatisticsFormated,
                    },
                    isTopAdVisible: parseInt(data.records.advertText.opened) === 1 || this.state.isTopAdVisible,
                    isLoaderVisible: false,
                });
            }, error => {
                this.setState({ isLoaderVisible: false });
            });

            if (this.props.login.privateClientData.showGameProgress === '1') {
                this.props.getTicketsRequest(
                    this.props.login.privateClientData.personalCode,
                    data => {
                        this.setState({ gameInfo: data, isLoaderVisible: false });
                    },
                    error => {
                        this.setState({ isLoaderVisible: false });
                    }
                );
            }

        });
    }

    componentDidMount() {
        this.downloadData();
        this.initMessagesListener(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (roleOrLanguageChanged(prevProps, this.props)) {
            this.downloadData();
        }
    }

    onUserDataUpdated = () => {
        this.downloadData();
    }


    initMessagesListener(elem) {
        document.addEventListener('message', function (e) {
            var message;

            console.log('message received from react native');
            try {
                message = JSON.parse(e.data);
            } catch (err) {
                console.error('failed to parse message from react-native ' + err);
                return;
            }

            switch (message.targetFunc) {
                case "download_data":
                    elem.downloadData();
                    break;
                default:
                    break;
            }
        });
    }

    render() {
        const welcomeText = (this.state.content && this.state.content.welcomeText) || null;
        const welcomeTextNotifications = (this.state.content && this.state.content.welcomeTextNotifications) || null;
        const infoBubble1 = (this.state.content && this.state.content.infoBubble1) || null;
        const infoBubble2 = (this.state.content && this.state.content.infoBubble2) || null;
        const infoBubble3 = (this.state.content && this.state.content.infoBubble3) || null;
        const advertText = (this.state.content && this.state.content.advertText) || null;
        const loyaltyProgress = (this.state.content && this.state.content.loyaltyProgress) || null;
        const userData = (this.state.content && this.state.content.userData) || null;
        const saleStatistics = (this.state.content && this.state.content.saleStatistics) || null;
        const loyaltyInfo = (this.state.content && this.state.content.loyaltyInfo) || null;
        const footerAdvertisement = this.state.content && this.state.content.footerAdvertisement;
        const footerColor = footerAdvertisement && footerAdvertisement.backgroundHex;
        const footerImg = footerAdvertisement && footerAdvertisement.image;
        const footerUrl = footerAdvertisement && footerAdvertisement.imageUrl;
        const discountCards = (this.state.content && this.state.content.discountCards) || null;
        const creditCards = (this.state.content && this.state.content.creditCards) || null;

        const showGameProgress = this.props.login && this.props.login.privateClientData.showGameProgress === '1';
        return (
            <div className={styles.overview}>
                <div className={`${styles.headerShadow} ${this.state.isTopAdVisible ? styles.topad : ''}`}></div>
                <div className={styles.header}>
                    <TransitionGroup component={null}>
                        {!this.state.isLoaderVisible &&
                            <CSSTransition key="welcome-fade" timeout={{ enter: 250, exit: 250 }} classNames="fast-fade welcome-text">
                                <Welcome {...{
                                    welcomeText,
                                    welcomeTextNotifications
                                }} />
                            </CSSTransition>}
                        {!this.state.isLoaderVisible &&
                            <CSSTransition key="dials-fade" timeout={{ enter: 250, exit: 250 }} classNames="fast-fade">
                                <Dials {...{
                                    onRightDialClick: this.onRightDialClick,
                                    infoBubble1,
                                    infoBubble2,
                                    infoBubble3,
                                }} /></CSSTransition>}
                    </TransitionGroup>
                </div>
                <TransitionGroup component={null}>
                    {this.state.isLoaderVisible &&
                        <CSSTransition key="loader-fade" timeout={{ enter: 250, exit: 250 }} classNames="fast-fade">
                            <Loader {...{ className: styles.headerLoader }} />
                        </CSSTransition>}

                    {this.state.isTopAdVisible &&
                        <CSSTransition key="top-loyalty-ad-fade" timeout={{ enter: 250, exit: 250 }} classNames="height">
                            <div className={styles.headerOverhang}>
                                <TopAd {...{ ...advertText, onTopAdClose: this.onTopAdClose }} />
                            </div>
                        </CSSTransition>}
                </TransitionGroup>
                <div className={styles.body}>
                    <OlerexPlus {...{
                        onShowAllToggle: () => this.onShowAllOpen(loyaltyProgress, loyaltyInfo),
                        loyaltyProgress: loyaltyProgress,
                        loyaltyInfo: loyaltyInfo,
                        isLoaderVisible: this.state.isLoaderVisible,
                    }} />

                    {
                        showGameProgress &&
                            <GameProgress
                            gameInfo={this.state.gameInfo}
                            isLoaderVisible={this.state.isLoaderVisible}
                        />
                    }

                    <UserDetails {...{
                        userData: userData,
                        login: this.props.login,
                        onUserDataUpdated: this.onUserDataUpdated,
                        isLoaderVisible: this.state.isLoaderVisible
                    }} />

                    <CreditCards {...{
                        className: (this.state.isLoaderVisible || (creditCards && creditCards.length > 0)) ? '' : 'invisible',
                        isLoaderVisible: this.state.isLoaderVisible,
                        creditCards: creditCards,
                        onModalOpen: this.props.onModalOpen,
                        onModalClose: () => {
                            this.downloadData();
                            this.props.onModalClose();
                        }
                    }} />

                    <Cards {...{
                        className: (this.state.isLoaderVisible || (discountCards && discountCards.length > 0)) ? '' : 'invisible',
                        isLoaderVisible: this.state.isLoaderVisible,
                        discountCards: discountCards,
                        onModalOpen: this.props.onModalOpen,
                        onModalClose: this.props.onModalClose
                    }} />
                </div>
                <div className={styles.footerShadow}></div>
                <div className={styles.footer}>
                    <FuelUsage {...{
                        saleStatistics: saleStatistics,
                        isLoaderVisible: this.state.isLoaderVisible
                    }} />
                </div>
                <div className={styles.adBackground} style={{ background: footerColor }}></div>
                <div className={styles.ad}>
                    <a href={footerUrl} target="_blank" rel="noopener noreferrer">
                        <img src={footerImg} alt="advertisement" />
                    </a>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        translation: state.translation.overview,
        login: state.login,
    }),
    dispatch => ({
        userDataChange: (userData) => dispatch(userDataChange(userData)),
        dashboardRequest: (body, success, error) =>  privateDashboardRequest(dispatch, body, success, error),
        getTicketsRequest: (personalCode, success, error) => getTicketsRequest(dispatch, personalCode, success, error)
    }))(Overview);
